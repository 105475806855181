import React, {Component} from 'react';
import Content from "../../common/containers/Content";
import PublicPage from "../../common/containers/PublicPage";
import Ajax from "../../common/ajax";
import Config from "../../config";
import Waiting from "../../common/containers/Waiting";
import {withNamespaces} from "react-i18next";
import Row from "../../common/containers/Row";
import Col from "../../common/containers/Col";
import LocaleUtils from "../../common/LocaleUtils";
import {Link} from "react-router-dom";
import URLS from "../../urls";

class PlanWholesalePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            processingPlan: true,
            processingOptions: true,
            plan: null,
        };
        this.planId = this.props.match.params.planId;
    }

    componentDidMount() {
        this.fetchPlan();
        this.fetchWholesaleOptions();
    }

    fetchPlan() {
        const url = `${Config.apiHost}plans/${this.planId}/`;
        Ajax.get(url).done(plan => {
            this.setState(state => ({
                ...state,
                plan,
            }));
        }).always(() => {
            this.setState(state => ({...state, processingPlan: false}));
        });
    }

    fetchWholesaleOptions() {
        const url = `${Config.apiHost}plans/${this.planId}/wholesale_options/`;
        Ajax.get(url).done(options => {
            this.setState(state => ({
                ...state,
                wholesaleOptions: options,
            }));
        }).always(() => {
            this.setState(state => ({...state, processingOptions: false}));
        });
    }


    render() {
        if (!this.state.plan) {
            return null;
        }
        const {i18n} = this.props;
        return (
            <PublicPage
                hasAside={false}
                title={i18n.t('Compras em grupo')}
                leftSidebarCollapsed={true}
            >
                <Content>
                    <ol className="breadcrumb">
                        <li>
                            <Link to={URLS.plans}>
                                {i18n.t('Preços')}
                            </Link>
                        </li>
                        <li>
                            {i18n.t('Compras em grupo')}
                        </li>
                    </ol>
                    <Waiting isProcessing={this.state.processingPlan || this.state.processingOptions}>
                        <Row>
                            <Col sm={8} mdOffset={2}>
                                <h2>
                                    {i18n.t('Selecione a quantidade de licenças desejada')}
                                    {/*<br/>*/}
                                    {/*<small>*/}
                                        {/*{i18n.t('Todas as licenças são para o plano')} <b>{this.state.plan.name}</b>.*/}
                                    {/*</small>*/}
                                </h2>
                                <hr/>
                                <Row>
                                    {this.state.wholesaleOptions &&
                                    this.state.wholesaleOptions.map(item => {
                                        const {
                                            full_price,
                                            amount,
                                            discount,
                                            discounted_price,
                                            installment_value,
                                            installment_quantity
                                        } = item;
                                        const amountPadded = String(amount).padStart(2, '0');
                                        const label = `${amountPadded} ${i18n.t('licenças')} ${this.state.plan.name}`;
                                        const labelPriceInstallments = `Em até ${installment_quantity}x ${LocaleUtils.currency(installment_value)} sem juros`;
                                        const fullPricePerUnit = full_price/amount;
                                        const pricePerUnit = discounted_price/amount;
                                        const linkParams = {
                                            pathname: URLS.pay(this.state.plan.id),
                                            amount: amount
                                        };
                                        return (
                                            <Col md={6} key={amount}>
                                                <div className="panel panel-contrast">
                                                    <div className="panel-heading panel-heading-contrast">
                                                        {label}
                                                        <div className="tools">
                                                            <span className="btn btn-default btn-sm">
                                                                {discount} {i18n.t('de desconto')}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body">
                                                        <Row>
                                                            <Col md={8}>
                                                                <p className="text-success">
                                                                    <b>
                                                                        {i18n.t('Valor por licença')}:
                                                                        {' '}
                                                                        <small>
                                                                            <strike>
                                                                                {LocaleUtils.currency(fullPricePerUnit)}
                                                                            </strike>
                                                                        </small>
                                                                        {' '}
                                                                        {LocaleUtils.currency(pricePerUnit)}
                                                                    </b>
                                                                </p>
                                                                <p>
                                                                    {i18n.t('Total')}:
                                                                    {' '}
                                                                    <small>
                                                                        <strike className="text-muted">
                                                                            {LocaleUtils.currency(full_price)}
                                                                        </strike>
                                                                    </small>
                                                                    {' '}
                                                                    {LocaleUtils.currency(discounted_price)}
                                                                </p>
                                                                <p className="text-muted">
                                                                    {labelPriceInstallments}
                                                                    </p>
                                                            </Col>
                                                            <Col md={4}>
                                                                <Link to={linkParams} className="btn btn-success btn-block">
                                                                    {i18n.t('Comprar')}
                                                                </Link>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8} mdOffset={2}>
                                <h3>Como funciona</h3>

                                <p>
                                    A compra em grupo possibilita que você ganhe descontos
                                    maiores ao adquirir várias licenças na mesma compra. É
                                    ideal para grupos e turmas de estudantes.
                                </p>

                                <ol>
                                    <li>Selecione a quantidade de licenças que gostaria de
                                        adquirir
                                    </li>
                                    <li>
                                        Informe os dados de pagamento solicitados
                                    </li>
                                    <li>
                                        Aguarde a confirmação de pagamento
                                    </li>
                                    <li>
                                        Após a confirmação de pagamento, você receberá a
                                        quantidade de licenças requeridas em forma de
                                        Vouchers. Os vouchers serão enviados por e-mail e
                                        também ficarão disponíveis em sua conta.
                                    </li>
                                    <li>
                                        Distribua os códigos dos vouchers com as pessoas
                                        de seu interesse. Cada código dá direito a resgatar
                                        uma licença no FastFormat.
                                    </li>
                                </ol>
                            </Col>
                        </Row>
                    </Waiting>
                </Content>
            </PublicPage>
        );
    }
}

export default withNamespaces()(PlanWholesalePage);
